<template>
  <div class="row" v-if="usernotification">
    <div class="col-sm-12 col-md-8 m-auto card">
      <div class="card-header">
        Notifications ({{ usernotification.allnotification.total }})
      </div>
      <!-- comments -->
      <div class="card-body">
        <div
          v-for="nItem in usernotification.allnotification.data"
          class="d-flex align-items-start mb-1"
        >
          <b-avatar src="comment.avatar" size="34" class="mt-25 mr-75" />
          <div class="profile-user-info w-100">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">
                {{ nItem.heading }}
              </h6>
              <b-link class="text-body">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click.prevent="make_as_read(nItem.id)"
                    class=""
                  >
                    <feather-icon icon="CheckIcon" class="mr-50" />
                    Mak as read
                  </b-dropdown-item>
                  <b-dropdown-item :to="nItem.redirect_path">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    View
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click.prevent="deleteNotification(nItem.id)"
                    class=""
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </b-link>
            </div>
            <p
              class="p-0 m-0"
              :class="nItem.status == 0 ? 'text-info font-weight-bolder' : ''"
            >
              {{ formatDateInAgo(nItem.created_at) }}
            </p>
            <small>{{ nItem.subheading }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ comments -->

  <!-- <app-timeline>
    <app-timeline-item
      v-for="nItem in usernotification.data"
      :title="nItem.heading"
      :subtitle="nItem.subheading"
      :icon="nItem.color ? nItem.color : 'AwardIcon'"
      :time="formatDateTime(nItem.created_at)"
      :variant="nItem.color ? nItem.color : 'primary'"
    />
  </app-timeline> -->
</template>

<script>
import axios from "axios";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  data() {
    return {};
  },
  computed: {
    usernotification() {
      return this.$store.getters.get_user_notification;
    },
  },
  created() {
    this.getNotification(10);
  },
  methods: {
    getNotification(item) {
      this.$store.dispatch("get_user_notification_action", {
        unseen: true,
        allnotification: true,
        item: item,
      });
    },
    make_as_read(id) {
      const currentuser = this.usernotification.allnotification.data.find(
        (ele) => ele.id == id
      );
      if (currentuser.status == 1) {
        this.$s_tost("Notification Already Seen");
        return false;
      }
      axios.get(`make-notification-read/${id}`).then((resp) => {
        if (resp.data.success) {
          currentuser.status = 1;
          this.$s_tost(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    deleteNotification(id) {
      axios.get(`delete-user-notification/${id}`).then((resp) => {
        if (resp.data.success) {
          this.usernotification.allnotification.data =
            this.usernotification.allnotification.data.filter(
              (i) => i.id != id
            );

          this.$s_tost(resp.data.message);
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
};
</script>
